import { Flex, Text, AspectRatio, Link } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system'

import { LogoFooter } from '@components/icons'

const Waiting = () => {
  return (
    <Flex
      w="full"
      h="100%"
      bg="#62513D"
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
      rowGap={10}
      py={{ base: 10, md: 20 }}
      px={{ base: 6, md: 10 }}
    >
      <Text color="white" fontSize={{ base: '1.5rem', md: '2rem' }} maxW="600px" align="center" lineHeight="3rem">
        Rendez-vous en septembre avec un nouveau site et nos campagnes en cours
      </Text>
      <AspectRatio width={{ base: '80%', md: '365px' }} ratio={3.65}>
        <Image alt="" src={s3ImageURL('regeneration.svg')} style={{ objectFit: 'cover' }} fill />
      </AspectRatio>
      <Link href="https://www.nestle.fr">
        <LogoFooter color="white" width={{ base: '50px', md: '75px' }} height="auto" />
      </Link>
    </Flex>
  )
}

export default Waiting
