import type { GetStaticProps } from 'next'
import Head from 'next/head'

import { Flex } from '@chakra-ui/react'

// import { Intro, Hero, FAQ, Together } from '@components/home'
import Waiting from '@components/home/Waiting'
import Footer from '@components/Footer'

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  }
}

const Home = () => {
  return (
    <>
      <Head>
        <title>Génération Régénération</title>
      </Head>
      <Flex direction="column" w="full" height="full">
        <Waiting />
        <Footer />
      </Flex>
      {/* <Hero />
      <Intro mt={16} />
      <Together /> */}
      {/* <FAQ /> */}
    </>
  )
}

export default Home
