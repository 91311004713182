import { Flex, Text, Container } from '@chakra-ui/react'

import { Facebook, LinkedIn, Twitter, LogoPartnerMiimosa, Instagram, Link } from '@miimosa/design-system'

const Footer = () => {
  return (
    <Container variant="full" as="footer" position="relative" bgColor="#897D6D">
      <Container variant="boxed" py={12}>
        <Flex align="center" direction={{ base: 'column', md: 'row' }} columnGap={16} rowGap={8}>
          <Link href="https://miimosa.com">
            <LogoPartnerMiimosa color="white" width="auto" height="64px" />
          </Link>
        </Flex>
        <Flex mt={12} align="center" columnGap={6} justify="center">
          <Link href="https://www.facebook.com/Nestle.fr/">
            <Facebook color="#31261D" />
          </Link>
          <Link href="https://www.instagram.com/nestleenfrance/?hl=fr">
            <Instagram color="#31261D" />
          </Link>
          <Link href="https://www.linkedin.com/company/nestlé-france-s.a.s./?originalSubdomain=fr">
            <LinkedIn color="#31261D" />
          </Link>
          <Link href="https://twitter.com/nestlefr">
            <Twitter color="#31261D" />
          </Link>
        </Flex>
      </Container>
      <Text
        as="div"
        display="flex"
        alignItems="center"
        justifyContent="center"
        size="3xs"
        color="white"
        bgColor="#897D6D"
        height="44px"
        borderTop={'1px solid'}
        borderColor="white"
        width="100%"
      >
        Fait avec ❤️ par MiiMOSA | Copyright © 2024 MiiMOSA
      </Text>
    </Container>
  )
}

export default Footer
